<template>
  <div id="release-note">
    <v-data-table
      :headers="header"
      :items="items"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      height="80vh"
      class="elevation-1 mb-5 draggable-table"
      hide-default-footer
      @page-count="pageCount = $event">
      <template #[`item.type`]="{ item }">
        <v-chip
          class="mb-1"
          :color="getReleaseNoteTypeColor(item.type)"
          text-color="white"
          small>
          {{ item.type }}
        </v-chip>
      </template>
      <template #[`item.roles`]="{ item }">
        <div class="d-flex flex-wrap">
          <v-chip
            v-for="(role, index) in item.roles"
            :key="`tag-selected-chip-${index}`"
            class="ma-1"
            small>
            {{ role }}
          </v-chip>
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          small
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          small
          @click="removeReleaseNote(item)" />
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Release Note
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            width="110px"
            @click="dialogToggle()">
            New Item
          </v-btn>
          <v-dialog
            v-model="dialog"
            max-width="600px">
            <v-card>
              <validation-observer
                ref="attributeForm"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col :cols="12">
                        <DatePicker
                          v-model="formData.dateString"
                          label="Date"
                          :rules="textFieldRules"
                          :readonly="modalLoading" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.title"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Title" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-textarea
                          v-model="formData.detail"
                          :readonly="modalLoading"
                          :rules="textFieldRules"
                          label="Detail" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-select
                          v-model="formData.type"
                          :rules="textFieldRules"
                          :items="types"
                          :readonly="modalLoading"
                          :menu-props="{ offsetY: true }"
                          label="Type"
                          item-text="name" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-autocomplete
                          v-model="formData.roles"
                          :items="roles"
                          :readonly="modalLoading"
                          label="Roles"
                          multiple
                          small-chips
                          clearable
                          deletable-chips />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ReleaseNoteProvider from '@/resources/ReleaseNoteProvider'
import DatePicker from '@/components/DatePicker.vue'

const ReleaseNoteService = new ReleaseNoteProvider()

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['dateString'],
        sortDesc: [true]
      },
      header: [
        {
          text: 'Type',
          value: 'type',
          width: 120,
          sortable: false
        },
        {
          text: 'Date',
          value: 'dateString',
          width: 120,
          sortable: false
        },
        {
          text: 'Title',
          value: 'title',
          width: 200,
          sortable: false
        },
        {
          text: 'Roles',
          value: 'roles',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 100,
          align: 'center'
        }
      ],
      items: [],
      isEdited: false,
      formData: {
        type: 'store',
        priority: 99999
      },
      textFieldRules: {
        required: true
      },
      numberFieldRules: {
        required: true,
        regex: /^[0-9]+$/
      },
      modalLoading: false,
      roles: [
        'non-specify',
        'management',
        'area_manager',
        'marketing',
        'developer',
        'designer',
        'inventory_and_costing',
        'merchandising_planner',
        'merchandiser',
        'creative',
        'graphic',
        'online_admin',
        'office_admin',
        'accounting_manager',
        'accounting',
        'support',
        'warehouse_manager',
        'warehouse',
        'store_manager',
        'acting_assist_store_manager',
        'assist_store_manager',
        'sales_staff'
      ],
      types: [
        {
          name: 'FEATURE',
          value: 'FEATURE',
          color: '#4CAF50'
        },
        {
          name: 'CHANGED',
          value: 'CHANGED',
          color: '#9C27B0'
        },
        {
          name: 'FIXED',
          value: 'FIXED',
          color: '#E91E63'
        },
        {
          name: 'ISSUE',
          value: 'ISSUE',
          color: '#F44336'
        },
        {
          name: 'ANNOUNCEMENT',
          value: 'ANNOUNCEMENT',
          color: '#03A9F4'
        },
        {
          name: 'NOTE',
          value: 'NOTE',
          color: '#607D8B'
        }
      ]
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit ReleaseNote' : 'New ReleaseNote'
    },
    search () {
      return this.$route.query.search ? this.$route.query.search : ''
    }
  },
  created () {
    this.getReleaseNote()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    getReleaseNoteTypeColor (type) {
      const colors = {}
      this.types.forEach((t) => { colors[t.name] = t.color })

      return colors[type] || '#607D8B'
    },
    setPageCount (event) {
      this.pageCount = event
    },
    async getReleaseNote () {
      try {
        this.loading = true
        const { data: { results } } = await ReleaseNoteService.getReleaseNote({ role: 'all', sortBy: 'dateString', sortOrder: 'desc' })
        this.items = results
      } catch (error) {
        console.error('getReleaseNote: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    dialogToggle () {
      this.dialog = true
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.formData = {
        priority: 99999
      }
    },
    editItem (item) {
      this.isEdited = true
      this.formData = {
        id: item.id,
        type: item.type,
        title: item.title,
        detail: item.detail,
        dateString: item.dateString,
        roles: item.roles
      }
      this.dialog = true
    },
    async save () {
      this.modalLoading = true
      const valid = await this.$refs.attributeForm.validate()
      if (valid) {
        let isSuccess = false
        try {
          const form = this.formData
          if (this.isEdited) {
            isSuccess = await this.updateReleaseNote(form)
          } else {
            isSuccess = await this.createReleaseNote(form)
          }
          this.closeFormModal()
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code}: ${error.message}`,
            type: 'error'
          })
          console.error('saveAttribute', error)
        } finally {
          if (isSuccess) {
            this.setSnackbar({
              value: true,
              message: 'Success',
              type: 'success'
            })
            this.getReleaseNote()
          }
        }
      }
      this.modalLoading = false
    },
    async createReleaseNote (item) {
      const { data } = await ReleaseNoteService.createReleaseNote(item)
      return !!data.id
    },
    async updateReleaseNote (item) {
      const { data } = await ReleaseNoteService.updateReleaseNote(item.id, item)
      return !!data.id
    },
    async removeReleaseNote (item) {
      this.setModal({
        value: true,
        title: 'Remove Release Note',
        message: 'Do you want to remove the Release Note',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveReleaseNote(item.id)
      })
    },
    async confirmRemoveReleaseNote (id) {
      try {
        const { data } = await ReleaseNoteService.deleteReleaseNote(id)
        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: 'Success',
            type: 'success'
          })
          if (this.options.page > 1 && this.items.length < 2) {
            this.options.page--
          } else {
            this.getReleaseNote()
          }
        } else {
          const error = {
            code: 400,
            message: 'Release Note ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemoveReleaseNote', error)
      }
    }
  }
}
</script>

<style scoped>
.vertical-top {
  vertical-align: top;
}

.theme--light.v-data-table.draggable-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid rgba(0, 0, 0, 0.12);
}
</style>
